<template>
  <div class="my-6">
    <h3 class="font-bold p-2">Lista de {{ route.name }}</h3>
    <span class="text-sm text-gray-500">Aqui você pode observar todos os itens relacionados aos {{
      route.name.toLowerCase() }} como
      suas respectivas ações.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <router-link to="/eventos/criar">
      <Button color="primary" :text="`Criar novo evento`" id="criarEvento" />
    </router-link>
  </div>
  <div class="card">
    <DataTable :headers="headers" :data="data" :options="options" color="primary" />
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";

import { inject, onMounted, ref } from "vue";
import { GET, PUT } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Eventos",
  components: {
    DataTable,
    Button,
  },
  setup() {

    const route = useRoute();
    const alert = inject("alert");
    const loader = inject("loading");
    const modal = inject("modal");
    const data = ref([]);
    const router = useRouter();



    const headers = [
      { key: "Id", value: "id" },
      { key: "Nome", value: "nome" },
      { key: "Estoque", value: "estoque2" },
      { key: "Data", value: "data", date: true },
      { key: "Ações", value: "" },
    ];

    const getEventos = async () =>{
      try {
        loader.open();
        data.value = await GET("event");
        //testando imagem no datatable - apagar depois de implementar o backend
        data.value = data.value.map(item => {
          return {
            ...item,
            nome: item.nome,
            estoque: item.integracao ? true : false,
            estoque2: item.integracao ? 'Habilitado' : 'Desabilitado'
          }
        })

        loader.close();
      } catch (e) {
        loader.close();
        alert.open("Erro", e, "danger");
      }
    }

    onMounted(async () => {
     await getEventos()
    });


    /*const mudarPoliticaEstoque = async (evento) => {
      loader.open();

      const mudarEstoqueFunction = async function () {

        try {
          loader.open();

          await PUT(`event/${evento.id}`,{'integracao_magento' : evento.estoque ? 0 : 1})


          await getEventos()
          loader.close();
          alert.open("Sucesso!", `politica mudada com sucesso!`, "success");
        } catch (error) {
          loader.close();
          alert.open("Erro", error, "danger");
        }
      }

      modal.open(
        "Atenção",
        `Deseja mudar a politica de estoque de ${evento.estoque? 'Ativado' : 'Desativado'}  para  ${!evento.estoque? 'Ativado' : 'Desativado'} desse evento?`,
        "warning",
        "Sim",
        () => mudarEstoqueFunction(),
        modal.close()
      );
      loader.close();
    }*/

    const options = [
      //{ icon: "triangle-exclamation", action: (param) => mudarPoliticaEstoque(param), title: "Desabiliar controle de estoque" },
      { icon: "pen", path: '/eventos/editar', title: "Editar evento" },
      { icon: "shield", path: '/eventos/posevento', title: "Pós evento" },

    ];

    return { headers, data, options, route};
  },
};
</script>

<style></style>